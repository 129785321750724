<template>
  <div class="content" style="max-height: 80vh">
    <el-row v-for="item of data" :key="item.id">
      <el-col style="margin: 2px auto; float: none">
        <el-card
          :body-style="{ padding: '0px 1rem' }"
          :shadow="item.done ? 'never' : 'always'"
        >
          <div style="padding: 10px">
            <span class="task_title" :class="item.done ? 'doneColor' : ''">{{
              item.title
            }}</span>
            <el-button
              v-show="!item.done"
              :size="size"
              class="button"
              :disabled="item.loading"
              :loading="item.loading"
              @click="accomplish(item)"
            >
              完成
            </el-button>
            <el-button
              v-show="item.done"
              :size="size"
              class="button"
              :disabled="true"
              >已完成</el-button
            >
            <div
              v-if="item.description"
              class="task-description"
              :class="item.done ? 'doneColor' : ''"
            >
              {{ item.description }}
            </div>

            <div class="bottom clearfix" :class="item.done ? 'doneColor' : ''">
              <span>开始日期：{{ item.startDate | formatDate }} ~</span>
              <span v-if="!item.endDate"
                >计划完成日期：{{ item.planEndDate | formatDate }}</span
              >
              <span v-if="item.endDate"
                >完成日期：{{ item.endDate | formatDate }}</span
              >
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="noData" class="noData">暂无内容</div>
  </div>
</template>

<script>
import Api from "api";
import axios from "@/common/js/ajax";
// import { normalMixin } from "@/common/js/mixin";
import { SUCC_MSG } from "@/common/js/config";
import notify from "@/common/js/notify";
// import time from "@/common/js/time";
// import { ability } from "@/ability/template";
// import { mapGetters } from "vuex";
// import { isDef } from "@/common/js/utils";
import { formatDate } from "@/common/js/filters";
// import _ from "lodash";
const ACCOMPLIST_SUCC_INFO = "任务" + SUCC_MSG;

export default {
  name: "TaskList",
  filters: {
    formatDate(value) {
      if (!value) return "无";
      return formatDate(value);
    },
  },

  props: {
    data: {
      default: () => [],
      type: Array,
    },
    noData: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      size: "mini",
    };
  },

  methods: {
    accomplish(item) {
      const { id } = item;
      const url = Api.putSchedulesDone.replace("{id}", id);
      item.loading = true;

      axios({
        method: "put",
        url: url,
      })
        .then(() => {
          item.loading = false;
          this.$emit("get-schedules");
          notify.succ({ message: ACCOMPLIST_SUCC_INFO });
        })
        .catch(() => {
          item.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.index-task {
  width 100%;
  .image-wrapper {
    background-image: url(../../../../assets/images/banner.png);
    width: 100%;
    height: 50rem;
    background-repeat: no-repeat;
    background-position: 50%, 50%;
  }
}

.time {
  font-size: 13px;
  color: #999;
}
.task_title {
  text-align: left;
  color: $succ;
}
.task-description {
  margin 0.5rem
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  font-size: 0.9rem
}
.underline {
  text-decoration: line-through;
}

.button {
  /* padding: 0; */
  float: right;
  border: 1px solid $prim;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.doneColor {
  color: #c0c4cc;
}

img {
  max-width: 100%;
  max-height: 100%;
}
.noData
    text-align: center;
    font-size: 1.2rem;
    color: #ccc;
    margin-top 3rem
</style>
